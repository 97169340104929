import { Button, Icon, Image } from "@chakra-ui/react";
import { logEvent } from "firebase/analytics";
import { useEffect, useState } from "react";
import { AiFillSound } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { doc, getDoc } from "firebase/firestore";
import { analytics, firestore } from "../firebase";
import { Gallery } from "../Gallery/Gallery";
import { windowSizeState } from "../recoil";
import logo from "./../Home/nz country.png";

const Speaker = () => {
  return <Icon as={AiFillSound} color={"#EA9101"} />;
};

export const Thar = () => {
  const nav = useNavigate();

  useEffect(() => {
    logEvent(analytics, "page_view", { page_title: "Thar" });
  }, []);

  // const sound = useRef<any>(
  //   new Audio(
  //     "url"
  //   )
  // );
  // const [isSound, setIsSound] = useRecoilState(soundState);
  // useEffect(() => {
  //   sound.current = new Audio(
  //     "url"
  //   );
  //   isSound ? sound.current.play() : sound.current.pause();
  // }, [isSound]);

  // useEffect(() => {
  //   isSound ? sound.current.play() : sound.current.pause();
  // }, [isSound]);

  const [images, setImages] = useState<any>(null);
  useEffect(() => {
    getDoc(doc(firestore, "siteImages/thar"))
      .then((snapDoc) => {
        setImages(() => ({ ...snapDoc.data() }));
      })
      .catch((err) => console.error("Error loading images"));
  }, []);

  const { width, height } = useRecoilValue(windowSizeState);

  return (
    <div
      style={{
        height: "100vh",
        overflowY: "hidden",
        backgroundColor: "rgba(0,0,0,.9)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: `${height / 20}px`,
          //paddingBottom: `${height / 20}px`,
          //overflowY: "scroll",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: `${(height / 28) * 3}px`,
            width: "95%",
            maxWidth: "1200px",

            color: "#B0B0A0",
            fontFamily: "anton",
            fontSize: width < 550 ? "30px" : `${width / 30}px`,
            borderRadius: "3px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100%",
              color: "#EA9101",
            }}
          >
            BULL TAHR
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "90%",
            maxWidth: "400px",
            minHeight: "40px",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="link"
            size="lg"
            color={"#B0B0A0"}
            onClick={() => nav("/")}
            fontFamily={"anton"}
            letterSpacing={1.5}
          >
            Home
          </Button>
          <Button
            variant="link"
            size="lg"
            color={"#B0B0A0"}
            onClick={() => nav("/trophy-Hunting")}
            fontFamily={"anton"}
            letterSpacing={1.5}
          >
            Trophy Hunts
          </Button>
          <Button
            variant="link"
            size="lg"
            color={"#B0B0A0"}
            onClick={() => nav("/team")}
            fontFamily={"anton"}
            letterSpacing={1.5}
          >
            The Team
          </Button>
          {/* {isSound ? (
            <IconButton
              variant={"link"}
              icon={<Speaker />}
              aria-label="Stop"
              onClick={() => {
                setIsSound(false);
                sound.current.pause();
              }}
            />
          ) : (
            <IconButton
              variant={"link"}
              icon={<Speaker />}
              aria-label="Play"
              onClick={() => {
                setIsSound(true);
                sound.current.play();
              }}
            />
          )} */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: `${(height / 20) * 16}px`,
            //backgroundColor: "whitesmoke",
            // backgroundImage: `url(${stag})`,
            backgroundPosition: "center",
            //objectPosition: "center",
            width: "95%",
            maxWidth: "1200px",
            //padding: "2rem",
            overflowY: "scroll",
            borderRadius: "3px",
            fontSize: width < 700 ? ".9rem" : "1.3rem",
            textAlign: "justify",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "1rem",
              //backgroundColor: "rgba(255,255,255,.7)",
              color: "#B0B0A0",
              width: "100%",
            }}
          >
            {" "}
            <div
              style={{
                width: "80%",
                paddingTop: "5rem",
                paddingBottom: "2rem",
                display: "flex",
                flexDirection: width > 1000 ? "row" : "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  paddingRight: width > 1000 ? "5rem" : "0rem",
                }}
              >
                NZ COUNTRY is your premier outfitter for Tahr hunting
                experiences. We hunt in some of the world's most rugged high
                alpine terrain - the Southern Alps of New Zealand. Immerse
                yourself in the breathtaking beauty of the New Zealand's
                mountain wilderness as you embark on an unforgettable adventure
                in pursuing the elusive Tahr in wild mountain habitat.
              </div>
              {images && (
                <div
                  style={{
                    marginTop: "1rem",
                    backgroundImage: `url(${images.firstImage})`,
                    backgroundPosition: width > 1000 ? "0px 0px" : "center",
                    backgroundSize: "cover",
                    objectFit: "contain",
                    color: "#EA9101",
                    width: "100%",
                    height: "100%",
                    minHeight: "250px",
                    textAlign: "center",
                    borderRadius: ".5rem",
                  }}
                ></div>
              )}
            </div>
            <div
              style={{
                width: "80%",
                paddingTop: "5rem",
                paddingBottom: "5rem",

                fontWeight: "bold",

                textAlign: "center",
              }}
            >
              Our Tahr hunts start at{" "}
              <span style={{ color: "#EA9101" }}>$7800 USD</span> per animal.
              This price includes being transported into the rugged Southern
              Alps by helicopter.
            </div>
            <div
              style={{
                width: "80%",
                paddingTop: "5rem",
                paddingBottom: "5rem",
                fontStyle: "italic",
                fontWeight: "bold",
                color: "#EA9101",
                textAlign: "center",
              }}
            >
              "It's been an extraordinary hunt...", Andy Eff - Tahr hunting
            </div>
            {images && (
              <div
                style={{
                  paddingTop: "2rem",
                  backgroundImage: `url(${images.secondImage})`,
                  backgroundPosition: width > 1000 ? "0px -50px" : "center",
                  backgroundSize: "cover",
                  objectFit: "contain",
                  color: "#EA9101",
                  width: "100%",
                  height: "400px",
                  textAlign: "center",
                }}
              ></div>
            )}
            <div
              style={{
                width: "80%",
                paddingTop: "4rem",
                paddingBottom: "4rem",
              }}
            >
              Our Bull Tahr hunting experiences offer avid hunters the
              opportunity to explore some of the most challenging and
              exhilarating terrain on the planet. Accessed by helicopter, our
              towering peaks and steep ridges, remote valleys and pristine
              mountain tarns, provide the perfect backdrop for an
              adrenaline-fueled adventure unlike no other.
            </div>
            <div
              style={{
                width: "80%",
                paddingTop: "5rem",
                paddingBottom: "5rem",
                fontStyle: "italic",
                fontWeight: "bold",
                color: "#EA9101",
                textAlign: "center",
              }}
            >
              Tahr hunting is a an essential NZ hunting experience
            </div>
            <div
              style={{
                width: "80%",
                paddingTop: "5rem",
                paddingBottom: "2rem",
                display: "flex",
                flexDirection: width > 1000 ? "row" : "column",
                alignItems: "center",
              }}
            >
              {images && (
                <div
                  style={{
                    paddingTop: "2rem",
                    backgroundImage: `url(${images.thirdImage})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    objectFit: "contain",
                    color: "#EA9101",
                    width: "100%",
                    height: "100%",
                    minHeight: "200px",
                    textAlign: "center",
                    borderRadius: ".5rem",
                  }}
                ></div>
              )}
              <div
                style={{
                  width: width > 1000 ? "80%" : "100%",
                  paddingLeft: width > 1000 ? "5rem" : "0rem",
                }}
              >
                <div style={{ paddingBottom: "1.2rem" }}>
                  Led by our team of expert guides, who possess an intimate
                  knowledge of the land and its inhabitants, and can move
                  through the mountains almost as swiftly as the Bull Tahr,
                  these hunts promise excitement, challenge, and unparalleled
                  opportunities for success.
                </div>
                <div>
                  With years of experience and a deep respect for the
                  wilderness, our guides will lead you on an unforgettable
                  journey through the rugged Southern Alps, where every step is
                  a testament to your determination and skill as a hunter.
                </div>
              </div>
            </div>
            <div
              style={{
                width: "80%",
                paddingTop: "4rem",
                paddingBottom: "4rem",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#141414",
                padding: "3rem",
              }}
            >
              <div
                style={{
                  padding: "3rem",
                  fontWeight: "bold",
                  fontSize: width < 800 ? "1.2rem" : "2rem",

                  color: "#EA9101",
                  textAlign: "center",
                }}
              >
                NZ COUNTRY also offers multi-animal packages for the ultimate
                hunting experience.
              </div>{" "}
              <Button
                variant={"outline"}
                color={"white"}
                onClick={() => nav("/packages")}
              >
                Click to use our pricing calculator
              </Button>
            </div>
            <div
              style={{
                paddingTop: "2rem",
                fontWeight: "bold",
                fontSize: "2rem",
              }}
            >
              BULL TAHR GALLERY
            </div>
            <div
              style={{
                paddingTop: "1rem",
                paddingBottom: "2rem",
                width: "100%",
              }}
            >
              {images?.gallery && <Gallery images={images.gallery} />}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100vh",
                backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/images%2FAndy%20Shooting%20Thar_1.26.1.jpg?alt=media&token=41fdc01b-d4e8-4fc5-b5c5-3d95ad9a2bf6)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                objectFit: "contain",
                width: "100%",
                maxWidth: "1200px",
                maxHeight: "400px",
                paddingTop: "2rem",
                paddingBottom: "3rem",
              }}
            >
              <div>
                <Image
                  src={logo}
                  width={width / 6}
                  style={{ width: width < 700 ? "250px" : `${width / 7}px` }}
                  loading="eager"
                  onClick={() => nav("/inquire")}
                />
              </div>
              <Button
                variant="outline"
                onClick={() => nav("/inquire")}
                color={"#EA9101"}
                borderColor={"#EA9101"}
              >
                Come hunt with us - Inquire
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
