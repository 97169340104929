import { atom } from "recoil";

export const windowSizeState = atom<{ width: number; height: number }>({
  default: { width: window.innerWidth, height: window.innerHeight },
  key: "windowSizeStateKey",
  effects: [
    ({ setSelf }) => {
      const listener = () =>
        setSelf({ width: window.innerWidth, height: window.innerHeight });

      window.addEventListener("resize", listener);

      return () => window.removeEventListener("resize", listener);
    },
  ],
});

export const soundState = atom<boolean>({
  default: false,
  key: "soundStateKey",
});

export const detailsFormState = atom<{
  animal: string;
  lastName: string;
  name: string;
  photography: boolean;
  range: boolean;
  stalking: boolean;
  phone: string;
  email: string;
  bushWalking: boolean;
  helicopters: boolean;
  bowHunting: boolean;
  message: string;
}>({
  default: {
    animal: "",
    lastName: "",
    name: "",
    photography: false,
    range: true,
    stalking: true,
    phone: "",
    email: "",
    bushWalking: false,
    helicopters: false,
    bowHunting: false,
    message: "",
  },
  key: "detailsFormStateKey",
});

export const currentHuntState = atom<{
  chamois: number;
  thar: number;
  goat: number;
  fallow: number;
  awaparaRam: number;
  redStag: number;
  extraDay: number;
  330: number;
  351: number;
  381: number;
  401: number;
  431: number;
  451: number;
  481: number;
  501: number;
  521: number;
  551: number;
}>({
  key: "CurrentHuntKey",
  default: {
    chamois: 0,
    thar: 0,
    goat: 0,
    fallow: 0,
    awaparaRam: 0,
    redStag: 0,
    extraDay: 0,
    330: 0,
    351: 0,
    381: 0,
    401: 0,
    431: 0,
    451: 0,
    481: 0,
    501: 0,
    521: 0,
    551: 0,
  },
});

export const pricesState = atom<Array<any>>({
  key: "PricesStateKey",
  default: [],
});
