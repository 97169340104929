import { Center, Image } from "@chakra-ui/react";
import image1 from "./Group_1.14.1.jpg";
import image2 from "./Rifle2_1.12.1.jpg";
import image3 from "./Bush_2.30.1.jpg";
import image4 from "./Rifle_1.10.1.jpg";
import image5 from "./Stag_1.8.1.jpg";
//import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const Gallery = ({ images }: { images: Array<string> }) => {
  //const imagesTemp = [image1, image2, image3, image4, image5];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        // overflowY: "scroll",
        backgroundColor: "rgba(10,10,10,.8)",
        borderRadius: "1rem",
      }}
    >
      {images && (
        <Carousel responsive={responsive} autoPlay infinite>
          {images.map((item, idx) => {
            return (
              <Image
                key={idx}
                src={item}
                //boxSize="500px"
                alt="image1"
                objectFit="cover"
              />
            );
          })}
        </Carousel>
      )}
    </div>
  );
};
