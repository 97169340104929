import {
  Button,
  Icon,
  IconButton,
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { logEvent } from "firebase/analytics";
import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { AiFillSound } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { analytics, firestore } from "../firebase";
import {
  currentHuntState,
  pricesState,
  soundState,
  windowSizeState,
} from "../recoil";
import logo from "./../Home/nz country.png";
import red from "./../TrophyHunting/nz country red deer.png";

const Speaker = () => {
  return <Icon as={AiFillSound} color={"#EA9101"} />;
};

type StagPriceType = { price: number; range: string; id: string };

export const Packages = () => {
  const nav = useNavigate();

  useEffect(() => {
    logEvent(analytics, "page_view", { page_title: "Packages" });
  }, []);
  const [price, setPrice] = useState<number>(0);
  const [prices, setPrices] = useRecoilState(pricesState);
  const [recommendedDuration, setRecommendedDuration] = useState(0);
  useEffect(() => {
    const unsub = onSnapshot(collection(firestore, "pricing"), (snapshot) => {
      setPrices([]);
      snapshot.forEach((snapDoc) => {
        setPrices((prev) => [...prev, { ...snapDoc.data(), id: snapDoc.id }]);
      });
    });

    return unsub;
  }, []);

  const [stagPrices, setStagPrices] = useState<Array<StagPriceType>>([]);
  const [showStag, setShowStag] = useState(false);
  useEffect(() => {
    const unsub = onSnapshot(
      collection(firestore, "pricing/redStag/head"),
      (snapshot) => {
        setStagPrices([]);
        snapshot.forEach((snapDoc) => {
          setStagPrices((prev) => [
            ...prev,
            { ...snapDoc.data(), id: snapDoc.id } as StagPriceType,
          ]);
        });
      }
    );

    return unsub;
  }, []);

  // const sound = useRef<any>(
  //   new Audio(
  //     "url"
  //   )
  // );
  // const [isSound, setIsSound] = useRecoilState(soundState);
  // useEffect(() => {
  //   sound.current = new Audio(
  //     "url"
  //   );
  //   isSound ? sound.current.play() : sound.current.pause();
  // }, [isSound]);

  // useEffect(() => {
  //   isSound ? sound.current.play() : sound.current.pause();
  // }, [isSound]);

  const { width, height } = useRecoilValue(windowSizeState);

  const [currentHunt, setCurrentHunt] = useRecoilState(currentHuntState);

  useEffect(() => {
    setCurrentHunt({
      chamois: 0,
      thar: 0,
      goat: 0,
      fallow: 0,
      awaparaRam: 0,
      redStag: 0,
      extraDay: 0,
      330: 0,
      351: 0,
      381: 0,
      401: 0,
      431: 0,
      451: 0,
      481: 0,
      501: 0,
      521: 0,
      551: 0,
    });
  }, []);

  useEffect(() => {
    setPrice(0);
    setRecommendedDuration(0);

    if (!currentHunt) return;

    Object.entries(currentHunt).forEach((key) => {
      prices
        .filter((item) => item.id === key[0])
        .forEach((item) => {
          if (item.name !== "Red Deer Stag" && key[1] > 0) {
            setPrice(
              (prev) =>
                prev + item.price * key[1] + item.duration * 400 * key[1]
            );
            setRecommendedDuration((prev) => prev + item.duration * key[1]);
          }
        });

      stagPrices
        .filter((item) => item.id === key[0])
        .forEach((item) => {
          if (key[1] > 0) {
            setPrice((prev) => prev + item.price * key[1] + 2 * 400 * key[1]);
            setRecommendedDuration((prev) => prev + 2 * key[1]);
          }
        });
    });
  }, [currentHunt, price, prices, stagPrices]);

  return (
    <div
      style={{
        height: "100vh",
        overflowY: "hidden",
        backgroundColor: "rgba(0,0,0,.9)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: `${height / 20}px`,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: `${(height / 28) * 3}px`,
            width: "95%",
            maxWidth: "1200px",
            color: "#EA9101",
            fontFamily: "anton",
            fontSize: width < 550 ? "30px" : `${width / 30}px`,
            borderRadius: "3px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100%",
            }}
          >
            THE ULTIMATE HUNT
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "90%",
            maxWidth: "400px",
            minHeight: "40px",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="link"
            size="lg"
            color={"#B0B0A0"}
            onClick={() => nav("/")}
            fontFamily={"anton"}
            letterSpacing={1.5}
          >
            Home
          </Button>
          <Button
            variant="link"
            size="lg"
            color={"#B0B0A0"}
            onClick={() => nav("/trophy-Hunting")}
            fontFamily={"anton"}
            letterSpacing={1.5}
          >
            Trophy Hunts
          </Button>
          <Button
            variant="link"
            size="lg"
            color={"#B0B0A0"}
            onClick={() => nav("/team")}
            fontFamily={"anton"}
            letterSpacing={1.5}
          >
            The Team
          </Button>
          {/* {isSound ? (
            <IconButton
              variant={"link"}
              icon={<Speaker />}
              aria-label="Stop"
              onClick={() => {
                setIsSound(false);
                sound.current.pause();
              }}
            />
          ) : (
            <IconButton
              variant={"link"}
              icon={<Speaker />}
              aria-label="Play"
              onClick={() => {
                setIsSound(true);
                sound.current.play();
              }}
            />
          )} */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: `${(height / 20) * 16}px`,
            backgroundPosition: "center",
            width: "95%",
            //maxWidth: "1200px",
            overflowY: "scroll",
            borderRadius: "3px",
            fontSize: width < 700 ? ".9rem" : "1.3rem",
            textAlign: "justify",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "1rem",
              paddingBottom: "1rem",
              width: "100%",
              color: "#B0B0A0",
            }}
          >
            <div
              style={{
                width: "80%",
                paddingTop: "4rem",
                paddingBottom: "4rem",
                textAlign: "center",
                maxWidth: "1200px",
              }}
            >
              Whether you want the complete New Zealand hunting experience that
              includes every animal we have to offer, or your just want to get
              that last trophy for your trophy room, our pricing calculator will
              help you build your ultimate hunting experience.
            </div>
            <div
              style={{
                width: "80%",
                paddingTop: "2rem",
                paddingBottom: "4rem",
                textAlign: "center",
              }}
            >
              <Button
                variant="outline"
                onClick={() => nav("/inquire")}
                color={"#EA9101"}
                borderColor={"#EA9101"}
              >
                Come hunt with us - Inquire
              </Button>
            </div>
            <div
              style={{
                width: "80%",
                paddingTop: "1rem",
                paddingBottom: "4rem",
                textAlign: "center",
                maxWidth: "1200px",
              }}
            >
              Designed to give you the best bang for your buck, our calculator
              lets you play with the prices and design your own hunt. We
              recommend a minimum number of days required to hunt each animal
              and so we calculate a daily base rate to ensure your only paying
              for what you need to.
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: width < 1250 ? "column" : "row",
                gap: "20px",
                //alignItems: "center",
                overflowX: "scroll",
                width: "100%",
                maxWidth: "1200px",
                //paddingLeft: width < 420 ? "2rem" : "0rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>Pricing Calculator</div>
                <TableContainer>
                  <Table variant="simple" size={width < 800 ? "sm" : "md"}>
                    <TableCaption>NZ COUNTRY price calculator</TableCaption>
                    <Thead color={"#EA9101"}>
                      <Tr>
                        {width > 799 && <Th></Th>}
                        <Th
                          style={{
                            color: "#EA9101",
                            fontSize: width < 800 ? ".6rem" : "1rem",
                          }}
                        >
                          Animal
                        </Th>
                        <Th
                          style={{
                            color: "#EA9101",
                            fontSize: width < 800 ? ".6rem" : "1rem",
                          }}
                        >
                          $USD
                        </Th>
                        <Th
                          style={{
                            color: "#EA9101",
                            fontSize: width < 800 ? ".6rem" : "1rem",
                          }}
                        >
                          Days*
                        </Th>
                        <Th
                          style={{
                            color: "#EA9101",
                            fontSize: width < 800 ? ".6rem" : "1rem",
                          }}
                        >
                          QTY
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        {width > 799 && (
                          <Td>
                            {/* <Avatar
                              name={"Red Deer Stag"}
                              size={width < 800 ? "sm" : "lg"}
                              bg="#000000"
                            /> */}
                            <Image src={red} boxSize={100} />
                          </Td>
                        )}
                        <Td>Red Deer Stag</Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>
                          <Button
                            size={width < 800 ? "xs" : "md"}
                            onClick={() =>
                              showStag ? setShowStag(false) : setShowStag(true)
                            }
                          >
                            {showStag ? "Hide" : "Show"}
                          </Button>
                        </Td>
                      </Tr>
                      {showStag &&
                        stagPrices.map((price, idx) => {
                          return (
                            <Tr key={idx}>
                              {width > 799 && (
                                <Td>
                                  <div>SCI</div>
                                </Td>
                              )}
                              <Td>{price.range}</Td>
                              <Td>{`$${price.price}`}</Td>
                              <Td>2</Td>
                              <Td>
                                <NumberInput
                                  size={width < 800 ? "sm" : "md"}
                                  min={0}
                                  max={5}
                                  defaultValue={0}
                                  w={90}
                                  onChange={(valueString, valueNumber) => {
                                    setCurrentHunt((prev) => {
                                      return {
                                        ...prev,
                                        [price.id]: valueNumber,
                                      };
                                    });
                                  }}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Td>
                            </Tr>
                          );
                        })}
                      {prices.length > 1 &&
                        [...prices]
                          .sort((a, b) => {
                            return a.order - b.order;
                          })
                          .map((price, idx) => {
                            if (price.id === "redStag" || price.id === "base")
                              return null;
                            return (
                              <Tr key={idx}>
                                {width > 799 && (
                                  <Td>
                                    {/* <Avatar
                                  name={price.name}
                                  size={width < 800 ? "sm" : "lg"}
                                  bg="#000000"
                                  src={
                                    price.profileImage
                                      ? price.profileImage
                                      : null
                                  }
                                /> */}
                                    <Image
                                      src={price.profileImage}
                                      boxSize={100}
                                    />
                                  </Td>
                                )}
                                <Td>{price.name}</Td>
                                <Td>{`$${price.price}`}</Td>
                                <Td>{price.duration}</Td>
                                <Td>
                                  <NumberInput
                                    size={width < 800 ? "sm" : "md"}
                                    min={0}
                                    max={5}
                                    defaultValue={0}
                                    w={90}
                                    onChange={(valueString, valueNumber) => {
                                      setCurrentHunt((prev) => {
                                        return {
                                          ...prev,
                                          [price.id]: valueNumber,
                                        };
                                      });
                                    }}
                                  >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                </Td>
                              </Tr>
                            );
                          })}
                    </Tbody>
                    {width < 1250 && (
                      <Tfoot>
                        <Tr>
                          {width > 799 && (
                            <Td style={{ color: "#EA9101" }}>YOUR HUNT</Td>
                          )}
                          <Td></Td>
                          <Td></Td>
                          <Td style={{ color: "#EA9101" }}>
                            {`${recommendedDuration} Days`}
                          </Td>
                          <Td
                            style={{ color: "#EA9101" }}
                          >{`$${price} USD`}</Td>
                        </Tr>
                      </Tfoot>
                    )}
                  </Table>
                </TableContainer>
              </div>
              <div>
                <div>Breakdown</div>
                <TableContainer>
                  <Table variant="simple" size={width < 800 ? "sm" : "md"}>
                    <Thead color={"#EA9101"}>
                      <Tr>
                        <Th
                          style={{
                            color: "#EA9101",
                            fontSize: width < 800 ? ".6rem" : "1rem",
                          }}
                        >
                          Animal
                        </Th>
                        <Th
                          style={{
                            color: "#EA9101",
                            fontSize: width < 800 ? ".6rem" : "1rem",
                          }}
                        >
                          QTY
                        </Th>
                        <Th
                          style={{
                            color: "#EA9101",
                            fontSize: width < 800 ? ".6rem" : "1rem",
                          }}
                        >
                          $USD
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {Object.entries(currentHunt).map((ent, idx) => {
                        if (ent[1] <= 0 || stagPrices.length < 1) return null;

                        if (ent[0].length <= 3) {
                          return (
                            <Tr key={idx}>
                              <Td>
                                {`Stag SCI ${
                                  stagPrices.filter(
                                    (item) => item.id === ent[0]
                                  )[0].range
                                }`}
                              </Td>{" "}
                              <Td>{ent[1]}</Td>
                              <Td>
                                {`$${
                                  stagPrices.filter(
                                    (item) => item.id === ent[0]
                                  )[0].price * ent[1]
                                } USD`}
                              </Td>
                            </Tr>
                          );
                        }

                        return (
                          <Tr key={idx}>
                            <Td>
                              {
                                prices.filter((item) => item.id === ent[0])[0]
                                  .name
                              }
                            </Td>
                            <Td>{ent[1]}</Td>
                            <Td>
                              {`$${
                                prices.filter((item) => item.id === ent[0])[0]
                                  .price * ent[1]
                              } USD`}
                            </Td>
                          </Tr>
                        );
                      })}
                      <Tr>
                        <Td>Day (base rate**)</Td>
                        <Td> {recommendedDuration}</Td>
                        <Td>{`$${recommendedDuration * 400} USD`}</Td>
                      </Tr>
                      <Tr>
                        <Td>TOTAL</Td>
                        <Td></Td>
                        <Td style={{ color: "#EA9101" }}>{`$${price} USD`}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
                <div style={{ paddingTop: "1rem" }}>
                  <Button
                    size={width < 800 ? "sm" : "md"}
                    variant="outline"
                    onClick={() => nav("/inquire")}
                    color={"#EA9101"}
                    borderColor={"#EA9101"}
                  >
                    Come hunt with us - Inquire
                  </Button>
                </div>
              </div>
            </div>
            <div
              style={{
                maxWidth: "500px",
                fontSize: ".75rem",
                padding: "1rem",
              }}
            >
              * Days are calculated as the minimum required number of days to
              hunt a particular animal
            </div>
            <div
              style={{
                maxWidth: "500px",
                fontSize: ".75rem",
                padding: "1rem",
              }}
            >
              ** The daily base rate includes accommodation, catering,
              refreshments, transportation, ammunition, and other expenses. It
              is calculated from the recommended duration of stay required to
              hunt each animal. These prices are indicative only. The price will
              be finalized on application
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100vh",
              backgroundColor: "#141414",
              width: "100%",
              maxWidth: "1200px",
              paddingTop: "2rem",
              paddingBottom: "3rem",
            }}
          >
            <div>
              <Image
                src={logo}
                width={width / 6}
                style={{ width: width < 700 ? "250px" : `${width / 7}px` }}
                loading="eager"
                onClick={() => nav("/inquire")}
              />
            </div>
            <Button
              variant="outline"
              onClick={() => nav("/inquire")}
              color={"#EA9101"}
              borderColor={"#EA9101"}
            >
              Come hunt with us - Inquire
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
