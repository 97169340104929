import { useRecoilValue } from "recoil";
import { windowSizeState } from "../recoil";
import { Image } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import right from "./Right.png";
import left from "./Left.png";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";

export const FixImages = ({ images }: { images: Array<string> }) => {
  const { width } = useRecoilValue(windowSizeState);
  const imageRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollInfo, setScrollInfo] = useState<{
    container: null | number;
    images: null | number;
  }>({ container: null, images: null });

  useEffect(() => {
    if (!imageRef.current?.scrollWidth || !containerRef.current?.scrollWidth)
      return;

    const update = setTimeout(() => {
      if (!imageRef.current?.scrollWidth || !containerRef.current?.scrollWidth)
        return;

      setScrollInfo({
        images: imageRef.current?.scrollWidth,
        container: containerRef.current?.scrollWidth,
      });
    }, 1000);

    setScrollInfo({
      images: imageRef.current?.scrollWidth,
      container: containerRef.current?.scrollWidth,
    });

    return () => clearTimeout(update);
  }, [imageRef.current?.scrollWidth]);

  return (
    <div
      ref={containerRef}
      style={{
        display: "flex",
        flexDirection: width < 800 ? "column" : "row",
        //justifyContent: "space-evenly",
        width: "100%",
        //overflowX: width < 800 ? "hidden" : "scroll",
        paddingTop: "4rem",
        paddingBottom: ".5rem",
        position: "relative",
      }}
    >
      <div
        ref={imageRef}
        style={{
          display: "flex",
          flexDirection: width < 800 ? "column" : "row",
          justifyContent: "space-evenly",
          //alignItems: "center",
          width: "100%",
          overflowX: width < 800 ? "hidden" : "scroll",
          scrollBehavior: "smooth",
          //paddingTop: "4rem",
          //paddingBottom: ".5rem",
          //position: "relative",
        }}
      >
        {images.map((url, idx) => (
          <Image
            key={idx}
            src={url}
            width={width < 800 ? width * 0.8 : width / 4}
            //boxSize="150px"
            objectFit="cover"
            style={{ maxHeight: "400px" }}
            onScroll={() => console.log("Loaded")}
          />
        ))}
      </div>
      {scrollInfo.container &&
        scrollInfo.images &&
        width >= 800 &&
        scrollInfo.images > scrollInfo.container && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              color: "white",
              fontSize: "2rem",
              zIndex: 30,
            }}
          >
            <div
              style={{ cursor: "pointer", padding: "2rem" }}
              onClick={() => {
                logEvent(analytics, "select_item", {
                  item_list_name: "Guide images",
                });
                imageRef.current?.scrollBy({ left: -450 });
              }}
            >
              <Image boxSize={50} src={left} />
            </div>
            <div
              style={{ cursor: "pointer", padding: "2rem" }}
              onClick={() => {
                logEvent(analytics, "select_item", {
                  item_list_name: "Guide images",
                });
                imageRef.current?.scrollBy({ left: 450 });
              }}
            >
              <Image boxSize={50} src={right} />
            </div>
          </div>
        )}
    </div>
  );
};
