import { Button, Icon, Image } from "@chakra-ui/react";
import { logEvent } from "firebase/analytics";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { AiFillSound } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { analytics, firestore } from "../firebase";
import { windowSizeState } from "../recoil";
import logo from "./../Home/nz country.png";

const Speaker = () => {
  return <Icon as={AiFillSound} color={"#EA9101"} />;
};

export const Chamois = () => {
  const nav = useNavigate();

  const loc = useLocation();
  useEffect(() => {
    logEvent(analytics, "page_view", { page_title: "Chamois" });
  }, [loc]);

  // const sound = useRef<any>(
  //   new Audio(
  //     "url"
  //   )
  // );
  // const [isSound, setIsSound] = useRecoilState(soundState);
  // useEffect(() => {
  //   sound.current = new Audio(
  //     "url"
  //   );
  //   isSound ? sound.current.play() : sound.current.pause();
  // }, [isSound]);

  const [images, setImages] = useState<any>(null);
  useEffect(() => {
    getDoc(doc(firestore, "siteImages/chamois"))
      .then((snapDoc) => {
        setImages(() => ({ ...snapDoc.data() }));
      })
      .catch((err) => console.error("Error loading images"));
  }, []);

  // useEffect(() => {
  //   isSound ? sound.current.play() : sound.current.pause();
  // }, [isSound]);

  const { width, height } = useRecoilValue(windowSizeState);

  return (
    <div
      style={{
        height: "100vh",
        overflowY: "hidden",
        backgroundColor: "rgba(0,0,0,.9)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: `${height / 20}px`,
          //paddingBottom: `${height / 20}px`,
          //overflowY: "scroll",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: `${(height / 28) * 3}px`,
            width: "95%",
            maxWidth: "1200px",
            color: "#EA9101",
            fontFamily: "anton",
            fontSize: width < 550 ? "30px" : `${width / 30}px`,
            borderRadius: "3px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100%",
            }}
          >
            CHAMOIS
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "90%",
            maxWidth: "400px",
            minHeight: "40px",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="link"
            size="lg"
            color={"#B0B0A0"}
            onClick={() => nav("/")}
            fontFamily={"anton"}
            letterSpacing={1.5}
          >
            Home
          </Button>
          <Button
            variant="link"
            size="lg"
            color={"#B0B0A0"}
            onClick={() => nav("/trophy-Hunting")}
            fontFamily={"anton"}
            letterSpacing={1.5}
          >
            Trophy Hunts
          </Button>
          <Button
            variant="link"
            size="lg"
            color={"#B0B0A0"}
            onClick={() => nav("/team")}
            fontFamily={"anton"}
            letterSpacing={1.5}
          >
            The Team
          </Button>
          {/* {isSound ? (
            <IconButton
              variant={"link"}
              icon={<Speaker />}
              aria-label="Stop"
              onClick={() => {
                setIsSound(false);
                sound.current.pause();
              }}
            />
          ) : (
            <IconButton
              variant={"link"}
              icon={<Speaker />}
              aria-label="Play"
              onClick={() => {
                setIsSound(true);
                sound.current.play();
              }}
            />
          )} */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: `${(height / 20) * 16}px`,
            //backgroundColor: "whitesmoke",
            //backgroundImage: `url(${stag})`,
            backgroundPosition: "center",
            //objectPosition: "center",
            width: "95%",
            maxWidth: "1200px",
            //padding: "2rem",
            overflowY: "scroll",
            borderRadius: "3px",
            fontSize: width < 700 ? ".9rem" : "1.3rem",
            textAlign: "justify",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "1rem",
              //backgroundColor: "rgba(255,255,255,.7)",
              color: "#B0B0A0",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "80%",
                paddingTop: "5rem",
                paddingBottom: "2rem",
                display: "flex",
                flexDirection: width > 1000 ? "row" : "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  paddingRight: width > 1000 ? "5rem" : "0rem",
                }}
              >
                Chamois in New Zealand, introduced in the early 20th century,
                have adapted remarkably well to the country's alpine regions in
                the South Island. These agile and elusive animals are prized by
                hunters for their challenging pursuit in the stunning, rugged
                terrain they inhabit. With their distinctive curved horns and
                keen senses, chamois offer a thrilling and rewarding hunting
                experience amidst New Zealand's breathtaking mountain scenery.
              </div>
              {images && (
                <div
                  style={{
                    marginTop: "1rem",
                    backgroundImage: `url(${images.firstImage})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    objectFit: "contain",
                    color: "#EA9101",
                    width: "100%",
                    height: "100%",
                    minHeight: "250px",
                    textAlign: "center",
                    borderRadius: ".5rem",
                  }}
                ></div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                //height: "100vh",
                width: "100%",
                maxWidth: "1200px",
                paddingTop: "6rem",
                paddingBottom: "3rem",
              }}
            >
              <Button
                variant={"outline"}
                color={"white"}
                onClick={() => nav("/packages")}
              >
                Click to use our pricing calculator
              </Button>
            </div>
            <div
              style={{
                width: "60%",
                paddingTop: "4rem",
                paddingBottom: "4rem",
                fontStyle: "italic",
                fontWeight: "bold",
                color: "#EA9101",
                textAlign: "center",
              }}
            >
              "This whole hunt has been fantastic. Mark and his guides have just
              been awesome!", Jim Eff - Red Deer Hunting
            </div>
            <div
              style={{
                width: "80%",
                paddingTop: "5rem",
                paddingBottom: "2rem",
                display: "flex",
                flexDirection: width > 1000 ? "row" : "column",
                alignItems: "center",
              }}
            >
              {images && (
                <div
                  style={{
                    paddingTop: "2rem",
                    backgroundImage: `url(${images.secondImage})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    objectFit: "contain",
                    color: "#EA9101",
                    width: "100%",
                    height: "100%",
                    minHeight: "200px",
                    textAlign: "center",
                    borderRadius: ".5rem",
                  }}
                ></div>
              )}
              <div
                style={{
                  width: width > 1000 ? "80%" : "100%",
                  paddingLeft: width > 1000 ? "5rem" : "0rem",
                }}
              >
                Hunting chamois with our experienced guides promises an
                exhilarating adventure in New Zealand's rugged alpine
                landscapes. With expert knowledge of chamois behavior and
                terrain navigation, our guides ensure strategic hunts that
                maximize success while creating memories that will last for
                years to come.
              </div>
            </div>
            <div
              style={{
                width: "60%",
                paddingTop: "4rem",
                paddingBottom: "4rem",
                fontStyle: "italic",
                fontWeight: "bold",
                color: "#EA9101",
                textAlign: "center",
              }}
            >
              Chamios are another New Zealand hunting classic. These animals are
              essential to complete the Ultimate New Zealand Hunt.
            </div>
            <div
              style={{
                width: "80%",
                paddingTop: "4rem",
                paddingBottom: "4rem",
              }}
            >
              Our two private hunting lodges offer luxurious comfort and warmth,
              providing an ideal sanctuary after a day in the field. Nestled
              amidst serene landscapes, each lodge ensures privacy and
              relaxation, allowing hunters to unwind and rejuvenate in
              tranquility.
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",

                backgroundColor: "#141414",
                width: "100%",
                maxWidth: "1200px",
                paddingTop: "2rem",
                paddingBottom: "3rem",
              }}
            >
              {" "}
              <div>
                <Image
                  src={logo}
                  width={width / 6}
                  style={{ width: width < 700 ? "250px" : `${width / 7}px` }}
                  loading="eager"
                  onClick={() => nav("/inquire")}
                />
              </div>
              <Button
                variant="outline"
                onClick={() => nav("/inquire")}
                color={"#EA9101"}
                borderColor={"#EA9101"}
              >
                Come hunt with us - Inquire
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
