import { Image } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { windowSizeState } from "../recoil";
import { MemberInfo } from "../types/typeDefs";
import { useEffect } from "react";
import { FixImages } from "./FixImages";

export const TeamMember = ({ teamMember }: { teamMember: MemberInfo }) => {
  const { width } = useRecoilValue(windowSizeState);

  useEffect(() => {}, []);
  return (
    <div
      style={{
        padding: "2rem",
        textAlign: "justify",
        //backgroundColor: "rgba(255,255,255,.6)",
        //borderRadius: "1rem",
        color: "#B0B0A0",
        fontSize: width < 500 ? ".75rem" : "1rem",
        marginBottom: "2rem",
      }}
    >
      {teamMember?.images.length > 0 && (
        <Image
          src={teamMember.images[0]}
          width={width > 500 ? "28%" : "70%"}
          style={{
            borderRadius: "10px",
            float: width < 600 ? "none" : "right",
            margin: "2rem",
          }}
        />
        // <GuideGallery images={teamMember?.images} />
      )}

      <div
        style={{
          fontSize: "2rem",
          fontFamily: "anton",
          paddingLeft: "1rem",
        }}
      >
        {teamMember?.name}
      </div>
      <div
        style={{
          fontSize: "1rem",
          fontFamily: "anton",
          paddingLeft: "1rem",
        }}
      >
        {teamMember?.title}
      </div>
      {teamMember?.bio.length > 0 &&
        teamMember?.bio.map((item, idx) => {
          return (
            <div key={idx} style={{ padding: "1rem" }}>
              {item}
            </div>
          );
        })}
      <FixImages images={teamMember.images} />
    </div>
  );
};
