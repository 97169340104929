import {
  Button,
  Icon,
  IconButton,
  Image,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useRecoilState, useRecoilValue } from "recoil";
import { soundState, windowSizeState } from "../recoil";
import { logEvent } from "firebase/analytics";
import { useEffect, useRef, useState } from "react";
import { AiFillSound } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { analytics, firestore } from "../firebase";
import logo from "./../Home/nz country.png";
import lodge from "./Lodge.jpg";
import stag from "./Stag.jpg";
import { Gallery } from "../Gallery/Gallery";
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";

const Speaker = () => {
  return <Icon as={AiFillSound} color={"#EA9101"} />;
};

export const RedDeer = () => {
  const nav = useNavigate();

  useEffect(() => {
    logEvent(analytics, "page_view", { page_title: "Red Deer" });
  }, []);

  const [sci, setSci] = useState<
    Array<{ price: number | string; range: string; id: string }>
  >([]);

  useEffect(() => {
    onSnapshot(collection(firestore, "pricing/redStag/head"), (snapshot) => {
      setSci([]);
      snapshot.forEach((snapDoc) => {
        setSci((prev) => [
          ...prev,
          { ...snapDoc.data(), id: snapDoc.id } as {
            price: number;
            range: string;
            id: string;
          },
        ]);
      });
    });
  }, []);

  const [images, setImages] = useState<any>(null);
  useEffect(() => {
    getDoc(doc(firestore, "siteImages/redStags"))
      .then((snapDoc) => {
        setImages(() => ({ ...snapDoc.data() }));
      })
      .catch((err) => console.error("Error loading images"));
  }, []);

  // const sound = useRef<any>(
  //   new Audio(
  //     "url"
  //   )
  // );
  // const [isSound, setIsSound] = useRecoilState(soundState);
  // useEffect(() => {
  //   sound.current = new Audio(
  //     "url"
  //   );
  //   isSound ? sound.current.play() : sound.current.pause();
  // }, [isSound]);

  // useEffect(() => {
  //   isSound ? sound.current.play() : sound.current.pause();
  // }, [isSound]);

  const { width, height } = useRecoilValue(windowSizeState);
  const [dollar, setDollar] = useState("USD");

  return (
    <div
      style={{
        height: "100vh",
        overflowY: "hidden",
        backgroundColor: "rgba(0,0,0,.9)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: `${height / 20}px`,
          //paddingBottom: `${height / 20}px`,
          //overflowY: "scroll",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: `${(height / 28) * 3}px`,
            width: "95%",
            maxWidth: "1200px",
            color: "#EA9101",
            fontFamily: "anton",
            fontSize: width < 550 ? "30px" : `${width / 30}px`,
            borderRadius: "3px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100%",
            }}
          >
            TROPHY RED STAGS
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "90%",
            maxWidth: "400px",
            minHeight: "40px",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="link"
            size="lg"
            color={"#B0B0A0"}
            onClick={() => nav("/")}
            fontFamily={"anton"}
            letterSpacing={1.5}
          >
            Home
          </Button>
          <Button
            variant="link"
            size="lg"
            color={"#B0B0A0"}
            onClick={() => nav("/trophy-Hunting")}
            fontFamily={"anton"}
            letterSpacing={1.5}
          >
            Trophy Hunts
          </Button>
          <Button
            variant="link"
            size="lg"
            color={"#B0B0A0"}
            onClick={() => nav("/team")}
            fontFamily={"anton"}
            letterSpacing={1.5}
          >
            The Team
          </Button>
          {/* {isSound ? (
            <IconButton
              variant={"link"}
              icon={<Speaker />}
              aria-label="Stop"
              onClick={() => {
                setIsSound(false);
                sound.current.pause();
              }}
            />
          ) : (
            <IconButton
              variant={"link"}
              icon={<Speaker />}
              aria-label="Play"
              onClick={() => {
                setIsSound(true);
                sound.current.play();
              }}
            />
          )} */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: `${(height / 20) * 16}px`,
            backgroundPosition: "center",
            width: "95%",
            maxWidth: "1200px",
            overflowY: "scroll",
            borderRadius: "3px",
            fontSize: width < 700 ? ".9rem" : "1.3rem",
            textAlign: "justify",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "1rem",
              paddingBottom: "1rem",
              width: "100%",
              color: "#B0B0A0",
            }}
          >
            {" "}
            <div
              style={{
                width: "80%",
                paddingTop: "5rem",
                paddingBottom: "2rem",
                display: "flex",
                flexDirection: width > 1000 ? "row" : "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  paddingRight: width > 1000 ? "5rem" : "0rem",
                }}
              >
                Welcome to the ultimate destination for Red Deer Stag Trophy
                hunting. Immerse yourself in the rugged beauty of New Zealand's
                wilderness as you embark on an unforgettable adventure in
                pursuit of the majestic Red Deer Stag.
              </div>
              {images && (
                <div
                  style={{
                    marginTop: "1rem",
                    backgroundImage: `url(${images.firstImage})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    objectFit: "contain",
                    color: "#EA9101",
                    width: "100%",
                    height: "100%",
                    minHeight: "250px",
                    textAlign: "center",
                    borderRadius: ".5rem",
                  }}
                ></div>
              )}
            </div>
            <div
              style={{
                width: "60%",
                paddingTop: "4rem",
                paddingBottom: "4rem",
                fontStyle: "italic",
                fontWeight: "bold",
                color: "#EA9101",
                textAlign: "center",
              }}
            >
              "This whole hunt has been fantastic. Mark and his guides have just
              been awesome!", Jim Eff
            </div>
            <div
              style={{
                width: "80%",
                paddingTop: "5rem",
                paddingBottom: "2rem",
                display: "flex",
                flexDirection: width > 1000 ? "row" : "column",
                alignItems: "center",
              }}
            >
              {images && (
                <div
                  style={{
                    paddingTop: "2rem",
                    backgroundImage: `url(${images.secondImage})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    objectFit: "contain",
                    color: "#EA9101",
                    width: "100%",
                    height: "100%",
                    minHeight: "200px",
                    textAlign: "center",
                    borderRadius: ".5rem",
                  }}
                ></div>
              )}
              <div
                style={{
                  width: width > 1000 ? "80%" : "100%",
                  paddingLeft: width > 1000 ? "5rem" : "0rem",
                }}
              >
                Navigate your way through West Coast rain forest or hop between
                clearings as you stalk into range to catch that first glimpse of
                the beautiful red stag. Offering different SCI sized stags on a
                pristine hunting block at the foot of the Southern Alps, NZ
                COUNTRY operates a luxury hunting outfit where you can take
                charge of creating the ultimate hunting adventure.
              </div>
            </div>
            <div
              style={{
                width: "60%",
                paddingTop: "4rem",
                paddingBottom: "4rem",
                fontStyle: "italic",
                fontWeight: "bold",
                color: "#EA9101",
                textAlign: "center",
              }}
            >
              Our guides have had years of experience hunting in some of the
              most unforgiving terrain. They know and understand the animals and
              the land better than anyone else.
            </div>
            <div style={{ paddingTop: "2rem" }}>
              <Image src={stag} width={600} style={{ borderRadius: ".5rem" }} />
            </div>
            <div
              style={{
                width: "80%",
                paddingTop: "4rem",
                paddingBottom: "4rem",
              }}
            >
              At the end of the days hunting activities, retreat into the warmth
              of our exclusive hunting lodges that make for the prefect retreat
              nestled amidst the stunning landscapes. Sit back and enjoy the
              opportunity to rest and eat well, sampling some of the spoils of
              the hunt cooked to perfection by our expert guides and chefs.
            </div>
            <div style={{ paddingTop: "2rem" }}>
              <Image src={lodge} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#141414",
                padding: "3rem",
              }}
            >
              <div
                style={{
                  padding: "3rem",
                  fontWeight: "bold",
                  fontSize: width < 800 ? "1.2rem" : "2rem",
                  color: "#EA9101",
                  textAlign: "center",
                }}
              >
                NZ COUNTRY also offers multi-animal packages for the ultimate
                hunting experience.
              </div>{" "}
              <Button
                variant={"outline"}
                color={"white"}
                onClick={() => nav("/packages")}
              >
                Click to see our pricing
              </Button>
            </div>
            <div
              style={{
                paddingTop: "2rem",
                fontWeight: "bold",
                fontSize: "2rem",
              }}
            >
              Red Stag Gallery
            </div>
            <div
              style={{
                paddingTop: "1rem",
                paddingBottom: "2rem",
                width: "100%",
              }}
            >
              {images && <Gallery images={images.gallery} />}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100vh",
              width: "100%",
              maxWidth: "1200px",
              paddingTop: "2rem",
              paddingBottom: "3rem",
            }}
          >
            <TableContainer>
              <Table
                variant="simple" //maxWidth={"400px"} width={"95%"}
                fontFamily={"anton"}
                size={width < 800 ? "sm" : "md"}
              >
                <TableCaption color={"#EA9101"}>
                  PRICING PER RED STAG
                  <div>We recommend allowing two days to hunt a Red stag.</div>
                  <div>
                    {" "}
                    The prices listed here include a daily base rate of two days
                    hunting.
                  </div>
                </TableCaption>
                <Thead fontFamily={"anton"}>
                  <Tr>
                    <Th color={"#EA9101"} fontFamily={"anton"} fontSize={20}>
                      STAG
                    </Th>
                    <Th
                      color={"#EA9101"}
                      isNumeric
                      fontFamily={"anton"}
                      fontSize={20}
                      onClick={() => {
                        dollar === "USD" ? setDollar("NZD") : setDollar("USD");
                      }}
                    >
                      {`PRICE (${dollar})`}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sci.map((item, idx) => {
                    return (
                      <Tr>
                        <Td color={"#F8F0E0"}>{`${item.range} SCI`}</Td>
                        {item.price === "POA" ? (
                          <Td isNumeric color={"#F8F0E0"}>
                            POA
                          </Td>
                        ) : (
                          <Td isNumeric color={"#F8F0E0"}>{`$${
                            //@ts-ignore
                            item.price + 800
                          } USD`}</Td>
                        )}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <Button
              variant={"outline"}
              color={"white"}
              onClick={() => nav("/packages")}
            >
              Click to use our pricing calculator
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100vh",
              backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/images%2FAndy%20300%20WSM_1.21.1.jpg?alt=media&token=f416e0e6-90a9-4899-9d68-0699d1fbf511)`,
              //backgroundColor: "#141414",
              backgroundPosition: "center",
              backgroundSize: "cover",
              objectFit: "contain",
              width: "100%",
              maxWidth: "1200px",
              paddingTop: "2rem",
              paddingBottom: "3rem",
            }}
          >
            <div>
              <Image
                src={logo}
                width={width / 6}
                style={{ width: width < 700 ? "250px" : `${width / 7}px` }}
                loading="eager"
                onClick={() => nav("/inquire")}
              />
            </div>
            <Button
              variant="outline"
              onClick={() => nav("/inquire")}
              color={"#EA9101"}
              borderColor={"#EA9101"}
            >
              Come hunt with us - Inquire
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
