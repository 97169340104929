import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyDrDPanhI4u0dLpyvyHToUVgdjbr2Fu9o0",
  authDomain: "nzcountry.firebaseapp.com",
  projectId: "nzcountry",
  storageBucket: "nzcountry.appspot.com",
  messagingSenderId: "209648721751",
  appId: "1:209648721751:web:e2bffdb4b2bd5b26dce812",
  measurementId: "G-8G7N7Q6C4K",
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
