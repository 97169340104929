import { ChakraProvider } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Home } from "./Home/Home";
import { RecoilRoot } from "recoil";
import { About } from "./About/About";
import { Chamois } from "./Chamois/Chamios";
import Video from "./Home/webreel.mp4";
import { Inquire } from "./Inquire/Inquire";
import { RedDeer } from "./RedDeer/RedDeer";
import { Team } from "./Team/Team";
import { Thar } from "./Thar/Thar";
import { TrophyHunting } from "./TrophyHunting/TrophyHunting";
import { Packages } from "./Packages/Packages";
import { AiOutlineCopyright } from "react-icons/ai";
import { OutReach } from "./OutReach/OutReach";
import redDeer from "./RedDeer/Stag.jpg";
import { Goat } from "./Goat/Goat";
import { Fallow } from "./Fallow/Fallow";
import { Rams } from "./Rams/Rams";

function App() {
  const [loading, setLoading] = useState(true);

  const vid = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!vid.current) return;

    vid.current.play();
  }, []);
  return (
    <RecoilRoot>
      <ChakraProvider>
        <BrowserRouter>
          <div>
            <div
              style={{ height: "100vh", width: "100vw", overflow: "hidden" }}
            >
              {loading && (
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    zIndex: "11",
                    left: "0px",
                    height: "100vh",
                    width: "100vw",
                    overflowY: "hidden",
                    backgroundImage: `url(${redDeer})`,
                    backgroundPosition: "center",
                  }}
                ></div>
              )}
              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  zIndex: "10",
                  left: "0px",
                  height: "100vh",
                  width: "100vw",
                  overflowY: "hidden",
                }}
              >
                <video
                  id="bannerVideo"
                  ref={vid}
                  autoPlay
                  muted
                  loop
                  style={{
                    objectFit: "cover",
                    height: "100vh",
                    width: "100vw",
                  }}
                  onLoadStart={() => setLoading(true)}
                  onLoadedData={(e) => {
                    setLoading(false);
                  }}
                >
                  <source src={Video} type="video/mp4" />
                </video>
              </div>
              {loading && (
                <div
                  style={{
                    position: "absolute",
                    top: "80vh",
                    zIndex: "10",
                    left: `${window.innerWidth / 2 - 30}px`,
                    textAlign: "center",
                    verticalAlign: "center",
                  }}
                >
                  LOADING...
                </div>
              )}
              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  zIndex: "15",
                  left: "0px",
                  height: "100vh",
                  width: "100vw",
                  overflowY: "hidden",
                  //background: "rgb(23,23,23",
                  //background: '-moz-linear-gradient(0deg, rgba(23,23,23,1) 0%, rgba(0,0,0,0.5298494397759104) 29%, rgba(0,0,0,0) 73%)',
                  //background: '-webkit-linear-gradient(0deg, rgba(23,23,23,1) 0%, rgba(0,0,0,0.5298494397759104) 29%, rgba(0,0,0,0) 73%)',
                  background:
                    "linear-gradient(0deg, rgba(23,23,23,1) 0%, rgba(0,0,0,0.5298494397759104) 30%, rgba(0,0,0,0) 60%)",
                  filter:
                    'progid:DXImageTransform.Microsoft.gradient(startColorstr="#171717",endColorstr="#000000",GradientType=1)',
                }}
              >
                <Routes>
                  <Route path="/" element={<Home />} />
                  {/* <Route path="/gallery" element={<Gallery />} /> */}
                  <Route path="/trophy-hunting" element={<TrophyHunting />} />
                  <Route path="/red-deer" element={<RedDeer />} />
                  <Route path="/thar" element={<Thar />} />
                  <Route path="/chamois" element={<Chamois />} />
                  <Route path="/rams" element={<Rams />} />
                  <Route path="/fallow" element={<Fallow />} />
                  <Route path="/goats" element={<Goat />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/inquire" element={<Inquire />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/packages" element={<Packages />} />
                  <Route path="/outreach" element={<OutReach />} />
                </Routes>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "absolute",
                  width: "100vw",
                  bottom: "5px",
                  fontSize: ".65rem",
                  padding: ".5rem",
                  zIndex: 100,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    color: "#EA9101",
                  }}
                >
                  <AiOutlineCopyright />
                  <div
                    style={{ paddingLeft: ".25rem" }}
                  >{`NZ COUNTRY ${new Date().getUTCFullYear()}`}</div>
                </div>
              </div>
            </div>
          </div>
        </BrowserRouter>
      </ChakraProvider>
    </RecoilRoot>
  );
}

export default App;
