import { Button, Icon, Image } from "@chakra-ui/react";
import { logEvent } from "firebase/analytics";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { AiFillSound } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { analytics, firestore } from "../firebase";
import { windowSizeState } from "../recoil";
import logo from "./../Home/nz country.png";

const Speaker = () => {
  return <Icon as={AiFillSound} color={"#EA9101"} />;
};

export const Goat = () => {
  const nav = useNavigate();

  useEffect(() => {
    logEvent(analytics, "page_view", { page_title: "Goat" });
  }, []);

  const [images, setImages] = useState<any>(null);
  useEffect(() => {
    getDoc(doc(firestore, "siteImages/goats"))
      .then((snapDoc) => {
        setImages(() => ({ ...snapDoc.data() }));
      })
      .catch((err) => console.error("Error loading images"));
  }, []);

  // const sound = useRef<any>(
  //   new Audio(
  //     "url"
  //   )
  // );
  // const [isSound, setIsSound] = useRecoilState(soundState);
  // useEffect(() => {
  //   sound.current = new Audio(
  //     "url"
  //   );
  //   isSound ? sound.current.play() : sound.current.pause();
  // }, [isSound]);

  // useEffect(() => {
  //   isSound ? sound.current.play() : sound.current.pause();
  // }, [isSound]);

  const { width, height } = useRecoilValue(windowSizeState);

  return (
    <div
      style={{
        height: "100vh",
        overflowY: "hidden",
        backgroundColor: "rgba(0,0,0,.9)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: `${height / 20}px`,
          //paddingBottom: `${height / 20}px`,
          //overflowY: "scroll",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: `${(height / 28) * 3}px`,
            width: "95%",
            maxWidth: "1200px",
            color: "#EA9101",
            fontFamily: "anton",
            fontSize: width < 550 ? "30px" : `${width / 30}px`,
            borderRadius: "3px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100%",
            }}
          >
            TROPHY GOATS
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "90%",
            maxWidth: "400px",
            minHeight: "40px",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="link"
            size="lg"
            color={"#B0B0A0"}
            onClick={() => nav("/")}
            fontFamily={"anton"}
            letterSpacing={1.5}
          >
            Home
          </Button>
          <Button
            variant="link"
            size="lg"
            color={"#B0B0A0"}
            onClick={() => nav("/trophy-Hunting")}
            fontFamily={"anton"}
            letterSpacing={1.5}
          >
            Trophy Hunts
          </Button>
          <Button
            variant="link"
            size="lg"
            color={"#B0B0A0"}
            onClick={() => nav("/team")}
            fontFamily={"anton"}
            letterSpacing={1.5}
          >
            The Team
          </Button>
          {/* {isSound ? (
            <IconButton
              variant={"link"}
              icon={<Speaker />}
              aria-label="Stop"
              onClick={() => {
                setIsSound(false);
                sound.current.pause();
              }}
            />
          ) : (
            <IconButton
              variant={"link"}
              icon={<Speaker />}
              aria-label="Play"
              onClick={() => {
                setIsSound(true);
                sound.current.play();
              }}
            />
          )} */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: `${(height / 20) * 16}px`,
            backgroundPosition: "center",
            width: "95%",
            maxWidth: "1200px",
            overflowY: "scroll",
            borderRadius: "3px",
            fontSize: width < 700 ? ".9rem" : "1.3rem",
            textAlign: "justify",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "1rem",
              paddingBottom: "1rem",
              width: "100%",
              color: "#B0B0A0",
            }}
          >
            {" "}
            <div
              style={{
                width: "80%",
                paddingTop: "5rem",
                paddingBottom: "2rem",
                display: "flex",
                flexDirection: width > 1000 ? "row" : "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  paddingRight: width > 1000 ? "5rem" : "0rem",
                }}
              >
                Discover the thrill of trophy goat hunting on New Zealand's
                stunning West Coast, where rugged landscapes and dense forests
                provide the perfect backdrop for an unforgettable adventure. Our
                expertly guided hunts offer enthusiasts the chance to track and
                harvest impressive feral goats.
              </div>
              {images && (
                // <div
                //   style={{
                //     marginTop: "1rem",
                //     backgroundImage: `url(${images.firstImage})`,
                //     backgroundPosition: "center",
                //     backgroundSize: "cover",
                //     objectFit: "contain",
                //     color: "#EA9101",
                //     width: "100%",
                //     height: "100%",
                //     minHeight: "250px",
                //     textAlign: "center",
                //     borderRadius: ".5rem",
                //   }}
                // ></div>
                <Image src={images.firstImage} style={{ maxHeight: "500px" }} />
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                //height: "100vh",
                width: "100%",
                maxWidth: "1200px",
                paddingTop: "6rem",
                paddingBottom: "3rem",
              }}
            >
              <Button
                variant={"outline"}
                color={"white"}
                onClick={() => nav("/packages")}
              >
                Click to use our pricing calculator
              </Button>
            </div>
            <div
              style={{
                width: "80%",
                paddingTop: "5rem",
                paddingBottom: "2rem",
                display: "flex",
                flexDirection: width > 1000 ? "row" : "column",
                alignItems: "center",
              }}
            >
              {images && (
                <div
                  style={{
                    paddingTop: "2rem",
                    backgroundImage: `url(${images.secondImage})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    objectFit: "contain",
                    color: "#EA9101",
                    width: "100%",
                    height: "100%",
                    minHeight: "200px",
                    textAlign: "center",
                    borderRadius: ".5rem",
                  }}
                ></div>
              )}
              <div
                style={{
                  width: width > 1000 ? "80%" : "100%",
                  paddingLeft: width > 1000 ? "5rem" : "0rem",
                }}
              >
                Whether you're a seasoned hunter looking to add another trophy
                to the trophy room or you're new to the sport, our team ensures
                a safe, responsible, and exhilarating experience. Come hunt for
                a trophy goat with NZ COUNTRY and we will help you craft a
                hunting story to remember.
              </div>
            </div>
            <div
              style={{
                width: "60%",
                paddingTop: "4rem",
                paddingBottom: "4rem",
                fontStyle: "italic",
                fontWeight: "bold",
                color: "#EA9101",
                textAlign: "center",
              }}
            >
              The NZ COUNTRY guide team is made up of passionate hunters and
              former military servicemen. Equipped with medical skills and high
              levels of bushcraft, this is a team you can trust.
            </div>
            <div
              style={{
                width: "80%",
                paddingTop: "4rem",
                paddingBottom: "4rem",
              }}
            >
              Included as part of the NZ COUNTRY experience is the chance to sit
              back, relax, and enjoy a well-deserved rest while savoring
              delicious meals, including some of the hunt's spoils expertly
              prepared by our skilled guides and chefs. Nestled in the beautiful
              West Coast scenery, our hunting lodges provide the perfect
              secluded get away.
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#141414",
                padding: "3rem",
              }}
            >
              <div
                style={{
                  padding: "3rem",
                  fontWeight: "bold",
                  fontSize: width < 800 ? "1.2rem" : "2rem",
                  color: "#EA9101",
                  textAlign: "center",
                }}
              >
                NZ COUNTRY also offers multi-animal packages for the ultimate
                hunting experience.
              </div>{" "}
              <Button
                variant={"outline"}
                color={"white"}
                onClick={() => nav("/packages")}
              >
                Click to see our pricing
              </Button>
            </div>
            {/* <div
              style={{
                paddingTop: "2rem",
                fontWeight: "bold",
                fontSize: "2rem",
              }}
            >
              Goat Gallery
            </div>
            <div
              style={{
                paddingTop: "1rem",
                paddingBottom: "2rem",
                width: "100%",
              }}
            >
              {images && <Gallery images={images.gallery} />}
            </div> */}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100vh",
              backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/images%2FAndy%20300%20WSM_1.21.1.jpg?alt=media&token=f416e0e6-90a9-4899-9d68-0699d1fbf511)`,
              //backgroundColor: "#141414",
              backgroundPosition: "center",
              backgroundSize: "cover",
              objectFit: "contain",
              width: "100%",
              maxWidth: "1200px",
              paddingTop: "2rem",
              paddingBottom: "3rem",
            }}
          >
            <div>
              <Image
                src={logo}
                width={width / 6}
                style={{ width: width < 700 ? "250px" : `${width / 7}px` }}
                loading="eager"
                onClick={() => nav("/inquire")}
              />
            </div>
            <Button
              variant="outline"
              onClick={() => nav("/inquire")}
              color={"#EA9101"}
              borderColor={"#EA9101"}
            >
              Come hunt with us - Inquire
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
